<template>
  <div class="px-40 border border-EEEEEE py-35 purseBox">
    <!-- 标签 -->
    <div class="flex align-center justify-between pb-20 border-bottom border-F4F4F4">
      <div class="font-20 text-333333">申请提现</div>
      <div class="font-12 text-666666 flex align-center">
        目前所在：<el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/myIndex' }">个人中心</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/myPurse' }">我的钱包</el-breadcrumb-item>
          <el-breadcrumb-item>申请提现</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!-- 内容 -->
    <div class="py-25">
      <div class="purseBox_cont">
        <div class="px-35 py-30 flex align-center justify-between bg-F4F4F4 mb-35">
          <div>
            <div class="text-555555 mb-20">当前可提现金额</div>
            <div class="font-20 text-FF524C">
              ￥ <span class="font-32">{{ cash_balance }}</span>
            </div>
          </div>
          <div class="font-14 text-999999 cursorp" @click="gowithdrawdepositDetail">
            提现明细
          </div>
        </div>
        <div class="flex align-center mb-25">
          <div class="mr-20">提取至</div>
          <div class="purseBox_cont_typeact">
            <!-- <img
              src="@/assets/img/card.png"
              class="mr-10"
              width="1.625rem"
              height="1.625rem"
              alt=""
            /> -->
            <el-image
              class="mr-10"
              style="width: 1.625rem; height: 1.625rem"
              :src="require('@/assets/img/card.png')"
              fit="cover"
            ></el-image>
            银行卡
          </div>
          <!-- <div class="purseBox_cont_type">
            <img
              src="@/assets/img/weixin.png"
              class="mr-10"
              width="1.625rem"
              height="1.625rem"
              alt=""
            />
            微信
          </div>
          <div class="purseBox_cont_type">
            <img
              src="@/assets/img/zhufubao.png"
              class="mr-10"
              width="1.625rem"
              height="1.625rem"
              alt=""
            />
            支付宝
          </div> -->
        </div>
        <div class="purseBox_cont_input mb-25">
          <el-input
            v-model="input"
            :placeholder="'可提现' + cash_balance + '元'"
            type="number"
          >
            <div slot="prefix" class="font-14 text-555555">￥</div>
            <div slot="suffix" class="font-14 text-FF524C" @click="allinput">全部</div>
          </el-input>
        </div>
        <div class="purseBox_cont_btn" @click="submit">提交</div>
      </div>
    </div>
  </div>
</template>

<script>
import req from "../../../utils/req";

export default {
  data() {
    return {
      input: "",
      cash_balance: "",
    };
  },
  mounted() {
    this.getdata();
  },
  methods: {
    gowithdrawdepositDetail() {
      this.$router.push({
        path: "/withdrawdepositDetail",
        query: {type: "withdrawdepositDetail",},
      });
    },
    allinput() {
      this.input = this.cash_balance;
    },
    submit() {
      let that = this;
      req
        .post("center/cash", {
          money: that.input,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.$message.success(res.msg);
          that.$router.go(-1);
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
    // 获取数据
    getdata() {
      let that = this;
      req
        .post("center/cashPage", {})
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.cash_balance = res.data.cash_balance;
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.purseBox {
  height: 100%;
  box-sizing: border-box;
  &_cont {
    &_btn {
      width: 7.6875rem;
      height: 2.1875rem;
      line-height: 2.1875rem;
      text-align: center;
      font-size: 0.875rem;
      color: #ffffff;
      background: #ff524c;
      border-radius: 1.125rem;
      cursor: pointer;
    }
    &_type {
      width: 8.9375rem;
      height: 3.3125rem;
      background: #ffffff;
      border: 0.0625rem solid #dcdcdc;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #666666;
      font-size: 0.875rem;
      margin-right: 0.375rem;
      cursor: pointer;
    }
    &_typeact {
      width: 8.9375rem;
      height: 3.3125rem;
      background: #ffffff;
      color: #333333;
      border: 0.0625rem solid #ef6247;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.875rem;
      margin-right: 0.375rem;
      cursor: pointer;
    }
    &_input {
      ::v-deep input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
      }
      ::v-deep .el-input__inner {
        -webkit-appearance: none;
        background-color: #f1f2f3;
        border-radius: 0;
        border: 0;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        font-size: inherit;
        height: 3.8125rem;
        line-height: 3.8125rem;
        outline: 0;
        padding: 0 1.875rem;
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: 32.25rem;
      }
      ::v-deep .el-input__prefix {
        top: 1.3125rem;
      }
      ::v-deep .el-input__suffix {
        top: 1.3125rem;
        right: 16.25rem;
        cursor: pointer;
      }
    }
  }
}
</style>
